import React, { useState } from 'react';

function Header() {
    const [showMenu, setShowMenu] = useState(false);
    const userName = "Miles Eu"; 

    return (
        <div className="header">
            <img className="logo" alt="" src="https://cdn.esurv.co.uk/wp-content/uploads/2017/05/logo-esurv-white-208x80.png"></img>
            <div className="user-menu" onClick={() => setShowMenu(!showMenu)}>
                {userName} ▼
                {showMenu && (
                    <ul className="dropdown-menu">
                        <li onClick={() => console.log('User logged out')}>Logout</li>
                    </ul>
                )}
            </div>
        </div>
    );
}

export default Header;
