import React, { useState } from 'react';
import axios from 'axios';

function CladdingSearch() {
  const [postcode, setPostcode] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false); // State to track if an error occurred

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false); // Reset error state on new submission
    setResult(null); // Reset previous result on new submission

    axios.post('/api/search', { postcode })
      .then((response) => {
        console.log(typeof response.data); // Should log 'object' for correct JSON responses

        // Explicitly check for ErrorCode in the response data to determine an error condition

        if ('ErrorCode' in response.data) {
          console.log('Error response:', response.data);
          setError(true); // Indicate an error condition
          setResult(null); // Ensure no result is shown
        } else {
          console.log('Successful response:', response.data);
          setError(false); // No error
          setResult(response.data); // Display the successful result
        }
      })
      .catch((error) => {
        console.error('Network or server error:', error);
        setError(true); // Handle network or server errors
      });
  };

  return (
    <div className="results">
      <form onSubmit={handleSubmit} className="search-form">
        <input
          type="text"
          placeholder="Postcode"
          value={postcode}
          onChange={(e) => setPostcode(e.target.value)}
          className="form-input" // Refer to CSS for .form-input
        />
        <button type="submit" className="form-button">Search</button>
      </form>
      {error ? (
        <p style={{ color: 'red' }}>Property not found.</p>
      ) : result ? (
        <div>
          <p>Rating: {result.Rating}</p>
          <p>Result Level: {result.ResultLevel}</p>
          <p>Lender Override Applied: {result.LenderOverrideApplied}</p>
        </div>
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
}


export default CladdingSearch;
