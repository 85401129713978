import React, { useState, useEffect } from 'react';
import CladdingSearch from './CladdingSearch';
import Header from './Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

function App() {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    fetch('/api/time').then(res => res.json()).then(data => {
      setCurrentTime(data.time);
    });
  }, []);

  return (
    <div className="App">

      <div className="App-overlay"> {/* This adds the semi-transparent overlay */}
        <Header />
        <div className="content">
          <h1>Welcome to e.surv Cladding Portal</h1>
          <header className="App-header">

            <p>The current time is {currentTime}.</p>
            <p>Live test, server time: {new Date().toLocaleTimeString()}</p>
            {/*<p>Cladding search: </p><CladdingSearch /><p /> */}
            <Router>
              <Routes>
                <Route path="/search" element={<CladdingSearch />} />

              </Routes>
            </Router>
          </header>
        </div>
      </div>
    </div>
  );
}

export default App;
